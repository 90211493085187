import React from "react"
import Layout from "../components/layout"
import InfoSection from "../components/InfoSection"

const Katalog: React.FC = () => (
  <Layout>
    <InfoSection title="Szkolenia">
      <div className="hero">
        <div className="hero-body">
          <p className="is-size-2 has-text-centered">Strona w budowie</p>
        </div>
      </div>
    </InfoSection>
  </Layout>
)

export default Katalog
